<template>
  <StaticView :heading="$t('pages.dataProtection')">
    <!-- GERMAN -->
    <div
      v-if="$i18n.locale === 'de'"
      class="grid grid-rows-auto leading-8 text-darkerGray text-justify"
    >
      <p class="data-header">1. Datenschutz auf einen Blick</p>
      <div class="my-4">
        <p class="italic">Allgemeine Hinweise</p>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Datenerfassung auf dieser Website</p>
        <p>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Wie erfassen wir Ihre Daten?</p>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p class="mt-3">
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Wofür nutzen wir Ihre Daten?</p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <p class="mt-3">
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">
        2. Hosting und Content Delivery Networks (CDN)
      </p>
      <div class="my-4">
        <p class="italic">Externes Hosting</p>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        </p>
        <p class="mt-3">
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p class="mt-3">
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p class="mt-3">Wir setzen folgenden Hoster ein:</p>
        <p class="mt-3">Microsoft Corporation</p>
        <p class="mt-3">
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">
          Abschluss eines Vertrages über Auftragsverarbeitung
        </p>
        <p>
          Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
          einen Vertrag über Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">
        3. Allgemeine Hinweise und Pflicht-informationen
      </p>
      <div class="my-4">
        <p class="italic">Datenschutz</p>
        <p class="">
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p class="mt-3">
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p class="mt-3">
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Hinweis zur verantwortlichen Stelle</p>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <div class="py-3 leading-7">
          <p>ESG Analytics GmbH</p>
          <p>Geschäftsführer Dr. Markus G. Bell</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Köln</p>
          <p>Deutschland</p>
          <p>info@ecore-rating.com</p>
        </div>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Speicherdauer</p>
        <p>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keinen anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z.B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </p>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
      </div>
      <div class="my-4">
        <p class="italic">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </p>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Recht auf Datenübertragbarkeit</p>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">SSL- bzw. TLS-Verschlüsselung</p>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Auskunft, Löschung und Berichtigung</p>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Recht auf Einschränkung der Verarbeitung</p>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </p>
        <p class="mt-3">
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </p>
        <p class="mt-3">
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </p>
        <p class="mt-3">
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </p>
        <p class="mt-3">
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p class="mt-3">
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">
        4. Datenerfassung auf dieser Website
      </p>
      <div class="my-4">
        <p class="italic">Cookies</p>
        <p>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
          kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
          Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
          Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
          gespeichert, bis Sie diese selbst löschen oder eine automatische
          Löschung durch Ihren Webbrowser erfolgt.
        </p>
        <p class="mt-3">
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
          gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
          bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
          Abwicklung von Zahlungsdienstleistungen).
        </p>
        <p class="mt-3">
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten
          oder Werbung anzuzeigen.
        </p>
        <p class="mt-3">
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z.
          B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.B.
          Cookies zur Messung des Webpublikums) erforderlich sind, werden auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
          andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von Cookies zur technisch
          fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
          eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt
          die Speicherung der betreffenden Cookies ausschließlich auf Grundlage
          dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
          jederzeit widerrufbar.
        </p>
        <p class="mt-3">
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <p class="mt-3">
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
          gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Server-Log-Dateien</p>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul class="list-disc list-inside py-3 pl-2">
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
        <p>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.
        </p>
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website – hierzu müssen die Server-Log-Files erfasst werden.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Kontaktformular</p>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
        </p>
        <p class="mt-3">
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>
        <p class="mt-3">
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
          – bleiben unberührt.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Anfrage per E-Mail oder Telefon</p>
        <p>
          Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage
          inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
          Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p class="mt-3">
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>
        <p class="mt-3">
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
          Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">5. eCommerce und Zahlungsanbieter</p>
      <div class="my-4">
        <p class="italic">Verarbeiten von Daten (Kunden- und Vertragsdaten)</p>
        <p>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt
          auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
          Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
          gestattet. Personenbezogene Daten über die Inanspruchnahme dieser
          Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur,
          soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
          Dienstes zu ermöglichen oder abzurechnen.
        </p>
        <p class="mt-3">
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>
      </div>
    </div>

    <!-- ENGLISH -->
    <div
      v-else-if="$i18n.locale === 'en'"
      class="grid grid-rows-auto leading-8 text-darkerGray text-justify"
    >
      <p class="data-header">1. data protection at a glance</p>
      <div class="my-4">
        <p class="italic">General information</p>
        <p>
          The following information provides a simple overview of what happens
          to your personal data when you visit this website. Personal data is
          any data by which you can be personally identified. For more detailed
          information on data protection, please refer to our data protection
          statement below this text.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Data collection on this website</p>
        <p>Who is responsible for data collection on this website?</p>
        <p>
          Data processing on this website is carried out by the website
          operator. You can find the contact details of the website operator in
          the section “Information on the data controller” in this privacy
          policy.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">How do we collect your data?</p>
        <p>
          On the one hand, your data is collected by you providing it to us.
          This may, for example, be data that you enter in a contact form.
        </p>
        <p>
          Other data is collected automatically or with your consent by our IT
          systems when you visit the website. This is mainly technical data
          (e.g. internet browser, operating system or time of page view). This
          data is collected automatically as soon as you enter this website.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">What do we use your data for?</p>
        <p>
          Some of the data is collected in order to ensure error-free provision
          of the website. Other data may be used to analyse your user behaviour.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">What rights do you have regarding your data?</p>
        <p>
          At any time you have the right to receive information free of charge
          about the origin, recipient and purpose of your stored personal data.
          You also have the right to request the correction or deletion of this
          data. If you have given your consent to data processing, you can
          revoke this consent at any time for the future. You also have the
          right to request the restriction of the processing of your personal
          data under certain circumstances. Furthermore, you have the right to
          lodge a complaint with the competent supervisory authority.
        </p>
        <p class="mt-3">
          You can contact us at any time about this and other questions on the
          subject of data protection.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">
        2. hosting and content delivery networks (CDN)
      </p>
      <div class="my-4">
        <p class="italic">External hosting</p>
        <p>
          This website is hosted by an external service provider (hoster). The
          personal data collected on this website is stored on the hoster’s
          servers. This may include, but is not limited to, IP addresses,
          contact requests, meta and communication data, contractual data,
          contact details, names, website accesses and other data generated via
          a website.
        </p>
        <p class="mt-3">
          The hoster is used for the purpose of fulfilling the contract with our
          potential and existing customers (Art. 6 para. 1 lit. b DSGVO) and in
          the interest of a secure, fast and efficient provision of our online
          offer by a professional provider (Art. 6 para. 1 lit. f DSGVO).
        </p>
        <p class="mt-3">
          Our hoster will only process your data insofar as this is necessary
          for the fulfilment of its service obligations and follow our
          instructions with regard to this data.
        </p>
        <p class="mt-3">We use the following hoster:</p>
        <p class="mt-3">Microsoft Corporation</p>
      </div>
      <div class="my-4">
        <p class="italic">Conclusion of a contract for processing agreement</p>
        <p>
          In order to ensure data protection-compliant processing, we have
          concluded a processing agreement with our hoster.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">
        3. General information and obligatory information
      </p>
      <div class="my-4">
        <p class="italic">Data protection</p>
        <p class="">
          The operators of these pages take the protection of your personal data
          very seriously. We treat your personal data confidentially and in
          accordance with the statutory data protection regulations and this
          data protection declaration.
        </p>
        <p class="mt-3">
          When you use this website, various personal data are collected.
          Personal data is data by which you can be personally identified. This
          Privacy Policy explains which information we collect and how we use
          it. It also explains how and for which purpose this is done.
        </p>
        <p class="mt-3">
          We would like to point out that data transmission on the Internet
          (e.g. when communicating by e-mail) can have security gaps. Complete
          protection of data against access by third parties is not possible.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Note on the responsible office</p>
        <p>The responsible body for data processing on this website is:</p>
        <div class="py-3">
          <p>ESG Analytics GmbH</p>
          <p>Managing Director: Dr. Markus G. Bell</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Cologne</p>
          <p>Germany</p>
          <p class="mt-4">Telephone: +49 221 69 04 80 93</p>
          <p>E-mail: info@ecore-rating.com</p>
        </div>
        <p>
          The controller is the natural or legal person who alone or jointly
          with others determines the purposes and means of the processing of
          personal data (e.g. names, e-mail addresses, etc.).
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Storage period</p>
        <p>
          Unless a more specific storage period is stated within this data
          protection declaration, your personal data will remain with us until
          the purpose for processing the data no longer applies. If you assert a
          legitimate request for deletion or revoke your consent to data
          processing, your data will be deleted unless we have other legally
          permissible reasons for storing your personal data (e.g. retention
          periods under tax or commercial law); in the latter case, the data
          will be deleted once these reasons no longer apply.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Revocation of your consent to data processing</p>
        <p>
          Many data processing operations are only possible with your express
          consent. You can revoke consent you have already given at any time.
          The legality of the data processing carried out until the revocation
          remains unaffected by the revocation.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">
          Right to object to data collection in specific cases and to direct
          marketing (Art. 21 GDPR)
        </p>
        <p>
          IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F DSGVO,
          YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA
          AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS
          ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE
          LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS DATA
          PROTECTION DECLARATION. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR
          PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS
          FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS,
          OR THE PROCESSING IS FOR THE PURPOSE OF ASSERTING, EXERCISING OR
          DEFENDING LEGAL CLAIMS (OBJECTION UNDER ARTICLE 21(1) DSGVO).
        </p>
        <p>
          IF YOUR PERSONAL DATA ARE PROCESSED FOR THE PURPOSE OF DIRECT
          MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING
          OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING;
          THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS RELATED TO SUCH DIRECT
          MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO
          LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT
          TO ARTICLE 21 (2) OF THE GERMAN DATA PROTECTION ACT).
        </p>
      </div>
      <div class="my-4">
        <p class="italic">
          Right of appeal to the competent supervisory authority
        </p>
        <p>
          In the event of breaches of the DSGVO, data subjects shall have a
          right of appeal to a supervisory authority, in particular in the
          Member State of their habitual residence, their place of work or the
          place of the alleged breach. The right of appeal is without prejudice
          to any other administrative or judicial remedy.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Right to data portability</p>
        <p>
          You have the right to have data that we process automatically on the
          basis of your consent or in performance of a contract handed over to
          you or to a third party in a common, machine-readable format. If you
          request the direct transfer of the data to another responsible party,
          this will only be done insofar as it is technically feasible.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">SSL or TLS encryption</p>
        <p>
          For security reasons and to protect the transmission of confidential
          content, such as orders or enquiries that you send to us as the site
          operator, this site uses SSL or TLS encryption. You can recognise an
          encrypted connection by the fact that the address line of the browser
          changes from “http://” to “https://” and by the lock symbol in your
          browser line.
        </p>
        <p>
          If SSL or TLS encryption is activated, the data you transmit to us
          cannot be read by third parties.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Information, deletion and correction</p>
        <p>
          Within the framework of the applicable legal provisions, you have the
          right at any time to free information about your stored personal data,
          its origin and recipient and the purpose of the data processing and,
          if applicable, the right to correction or deletion of this data. You
          can contact us at any time with regard to this and any other questions
          you may have on the subject of personal data.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Right to restriction of processing</p>
        <p>
          You have the right to request the restriction of the processing of
          your personal data. To do this, you can contact us at any time. The
          right to restriction of processing exists in the following cases:
        </p>
        <p class="mt-3">
          If you dispute the accuracy of your personal data stored by us, we
          usually need time to check this. For the duration of the verification,
          you have the right to request the restriction of the processing of
          your personal data.
        </p>
        <p class="mt-3">
          If the processing of your personal data happened/is happening
          unlawfully, you can request the restriction of data processing instead
          of erasure.
        </p>
        <p class="mt-3">
          If we no longer need your personal data, but you need it to exercise,
          defend or assert legal claims, you have the right to request
          restriction of the processing of your personal data instead of
          erasure.
        </p>
        <p class="mt-3">
          If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a
          balancing of your and our interests must be carried out. As long as it
          has not yet been determined whose interests prevail, you have the
          right to demand the restriction of the processing of your personal
          data.
        </p>
        <p class="mt-3">
          If you have restricted the processing of your personal data, this data
          may – apart from being stored – only be processed with your consent or
          for the assertion, exercise or defence of legal claims or for the
          protection of the rights of another natural or legal person or for
          reasons of an important public interest of the European Union or a
          Member State.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">4. data collection on this website</p>
      <div class="my-4">
        <p class="italic">Cookies</p>
        <p>
          Our internet pages use so-called “cookies”. Cookies are small text
          files and do not cause any damage to your terminal device. They are
          stored either temporarily for the duration of a session (session
          cookies) or permanently (permanent cookies) on your end device.
          Session cookies are automatically deleted at the end of your visit.
          Permanent cookies remain stored on your end device until you delete
          them yourself or until they are automatically deleted by your web
          browser.
        </p>
        <p class="mt-3">
          In some cases, cookies from third-party companies may also be stored
          on your terminal device when you enter our site (third-party cookies).
          These enable us or you to use certain services of the third-party
          company (e.g. cookies for processing payment services).
        </p>
        <p class="mt-3">
          Cookies have various functions. Many cookies are technically
          necessary, as certain website functions would not work without them
          (e.g. the shopping cart function or the display of videos). Other
          cookies are used to evaluate user behaviour or display advertising.
        </p>
        <p class="mt-3">
          Cookies that are necessary to carry out the electronic communication
          process (necessary cookies) or to provide certain functions desired by
          you (functional cookies, e.g. for the shopping cart function) or to
          optimise the website (e.g. cookies to measure the web audience) are
          stored on the basis of Art. 6 (1) lit. f DSGVO, unless another legal
          basis is specified. The website operator has a legitimate interest in
          storing cookies for the technically error-free and optimised provision
          of its services. If consent to the storage of cookies has been
          requested, the storage of the cookies in question is based exclusively
          on this consent (Art. 6 para. 1 lit. a DSGVO); consent can be revoked
          at any time.
        </p>
        <p class="mt-3">
          You can set your browser so that you are informed about the setting of
          cookies and only allow cookies in individual cases, exclude the
          acceptance of cookies for certain cases or in general and activate the
          automatic deletion of cookies when closing the browser. If you
          deactivate cookies, the functionality of this website may be limited.
        </p>
        <p class="mt-3">
          If cookies are used by third-party companies or for analysis purposes,
          we will inform you about this separately within the framework of this
          data protection declaration and, if necessary, request your consent.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Server log files</p>
        <p>
          The provider of the pages automatically collects and stores
          information in so- called server log files, which your browser
          automatically transmits to us. These are:
        </p>
        <ul class="list-disc list-inside py-3 pl-2">
          <li>browser type and browser version</li>
          <li>operating system used</li>
          <li>referrer URL</li>
          <li>host name of the accessing computer</li>
          <li>time of the server request</li>
          <li>IP address</li>
        </ul>
        <p>This data is not merged with other data sources.</p>
        <p>
          The collection of this data is based on Art. 6 para. 1 lit. f DSGVO.
          The website operator has a legitimate interest in the technically
          error-free presentation and optimisation of its website – for this
          purpose, the server log files must be collected.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Contact form</p>
        <p>
          If you send us enquiries via the contact form, your details from the
          enquiry form, including the contact data you have provided there, will
          be stored by us for the purpose of processing the enquiry and in the
          event of follow-up questions. We do not pass this data on without your
          consent.
        </p>
        <p class="mt-3">
          The processing of this data is based on Art. 6 (1) lit. b DSGVO if
          your request is related to the performance of a contract or is
          necessary for the implementation of pre-contractual measures. In all
          other cases, the processing is based on our legitimate interest in the
          effective handling of the enquiries addressed to us (Art. 6 (1) (f)
          DSGVO) or on your consent (Art. 6 (1) (a) DSGVO) if this has been
          requested.
        </p>
        <p class="mt-3">
          The data you enter in the contact form will remain with us until you
          request us to delete it, revoke your consent to store it or the
          purpose for storing the data no longer applies (e.g. after we have
          completed processing your enquiry). Mandatory legal provisions – in
          particular retention periods – remain unaffected.
        </p>
      </div>
      <div class="my-4">
        <p class="italic">Enquiry by e-mail or telephone</p>
        <p>
          If you contact us by e-mail or telephone, your enquiry including all
          personal data resulting from it (name, enquiry) will be stored and
          processed by us for the purpose of processing your request. We do not
          pass this data on without your consent.
        </p>
        <p class="mt-3">
          The processing of this data is based on Art. 6 (1) lit. b DSGVO if
          your request is related to the performance of a contract or is
          necessary for the implementation of pre-contractual measures. In all
          other cases, the processing is based on our legitimate interest in the
          effective handling of the enquiries addressed to us (Art. 6 (1) (f)
          DSGVO) or on your consent (Art. 6 (1) (a) DSGVO) if this has been
          requested.
        </p>
        <p class="mt-3">
          The data you send us via contact requests will remain with us until
          you request us to delete it, revoke your consent to store it or the
          purpose for storing the data no longer applies (e.g. after your
          request has been processed). Mandatory statutory provisions – in
          particular statutory retention periods – remain unaffected.
        </p>
      </div>

      <p class="data-header mt-[1.25rem]">5. eCommerce and payment providers</p>
      <div class="my-4">
        <p class="italic">Processing of data (customer and contract data)</p>
        <p>
          We collect, process and use personal data only to the extent that it
          is necessary for the establishment, content or amendment of the legal
          relationship (inventory data). This is done on the basis of Art. 6
          para. 1 lit. b DSGVO, which permits the processing of data for the
          fulfilment of a contract or pre-contractual measures. We collect,
          process and use personal data about the use of this website (usage
          data) only insofar as this is necessary to enable the user to use the
          service or to bill the user.
        </p>
        <p class="mt-3">
          The collected customer data will be deleted after completion of the
          order or termination of the business relationship. Statutory retention
          periods remain unaffected.
        </p>
      </div>
    </div>
  </StaticView>
</template>

<script>
import StaticView from '@/components/layout/StaticView'
export default {
  components: {
    StaticView,
  },
}
</script>

<style scoped>
.data-header {
  @apply text-[20px] font-semibold uppercase;
}
</style>
